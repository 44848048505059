import { useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

// import { Button } from "@atd/components";
import { useRegisterAccountMutation } from "@atd/features/user";
import { getErrorMessage, setCredentialCookies } from "@atd/utils";
import { StripeSchema } from "@atd/schema";

import { ErrorMessage } from "./ErrorMessage";

import { countires } from "../../utils/countries";

import icon from "../../assets/icons/DoubleArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlan, setFormSubmitting } from "../../features";

export const Captcha = styled.div`
  & canvas {
    vertical-align: super;
    border-radius: 4px;
    width: 100%;
    margin-top: -5px;
  }
`;

function PaymentForm({
  uId,
  stripe,
  planId,
  isLoading,
  promoData,
  stripeToken,
  handleSubmit,
  cardElement,
}) {
  const refRecaptcha = useRef(null);
  const [registerAccount] = useRegisterAccountMutation();

  const dispatch = useDispatch();
  const { currentPlan, quantity } = useSelector((store) => store.auth);
  useEffect(() => {
    dispatch(setCurrentPlan(planId));
  }, []);

  const onSubmit = async (values, formikAction) => {
    const { setSubmitting } = formikAction;
    try {
      const {
        country: addressCountry,
        state,
        postal_code,
        line1,
        city,
        full_name,
      } = values;

      let stripeCreateToken;
      let tokenId = stripeToken;

      if (!stripeToken && stripe) {
        const additionalData = {
          name: full_name ?? undefined,
          address_line1: line1 ?? undefined,
          address_city: city ?? undefined,
          address_state: state ?? undefined,
          address_zip: postal_code ?? undefined,
          address_country: addressCountry ?? undefined,
        };

        stripeCreateToken = await stripe.current.createToken(
          cardElement.current,
          additionalData,
        );

        if (stripeCreateToken?.error) {
          toast.error(stripeCreateToken?.error.message);
          return;
        }

        const { token } = stripeCreateToken;

        // const { id: tokenId } = token || {};
        tokenId = token?.id;
      }

      let { code } = promoData || {};

      const planIds = {
        s_11: "s_11",
        p_11: "p_11",
        pr_11: "pr_11",
        ul_11: "ul_11",
      };

      const current_Plan_Id = currentPlan || planId || "s_1";
      // MONTH_ONLY
      if (
        planIds[current_Plan_Id] === current_Plan_Id &&
        (promoData?.code === "" || promoData == null)
      ) {
        code = process.env.REACT_APP_COUPON_50_OFF_MONTHLY;
      }

      // QUARTERLY & YEARLY

      if (promoData?.code === "" || promoData === null) {
        const couponMap = {
          s_22: process.env.REACT_APP_COUPON_50_OFF_QUARTERLY_STD,
          p_22: process.env.REACT_APP_COUPON_50_OFF_QUARTERLY_PRO,
          pr_22: process.env.REACT_APP_COUPON_50_OFF_QUARTERLY_PRE,
          ul_22: process.env.REACT_APP_COUPON_50_OFF_QUARTERLY_ULT,
          s_44: process.env.REACT_APP_COUPON_50_OFF_YEARLY_STD,
          p_44: process.env.REACT_APP_COUPON_50_OFF_YEARLY_PRO,
          pr_44: process.env.REACT_APP_COUPON_50_OFF_YEARLY_PRE,
          ul_44: process.env.REACT_APP_COUPON_50_OFF_YEARLY_ULT,
        };
        code = couponMap[current_Plan_Id] || code;
      }

      const payload = {
        city,
        line1,
        full_name,
        postal_code,
        dpu: quantity ?? 1,
        country: addressCountry,
        temp_user_id: uId,
        plan_id: currentPlan || planId || "s_1",
        stripe_token: tokenId,
        promo_code: code ?? "",
        recaptcha: undefined,
        card_type: stripeCreateToken?.token?.card?.brand,
        card_country: stripeCreateToken?.token?.card?.country,
      };

      const response = await registerAccount({ body: payload }).unwrap();
      const { status, message, results } = response || {};

      if (status === "success") {
        toast.success(message);
        // set this data in your cookies
        setCredentialCookies(results);
        // Redirect user to dashboard
        // resetForm({});
        window.location.replace("/requests");
      } else {
        const errorMessage = getErrorMessage(response);
        toast.error(errorMessage);
      }

      if (handleSubmit) {
        handleSubmit(values, formikAction);
      }
    } catch (error) {
      setSubmitting(false);
      // dispatch(setFormSubmitting(false));
    } finally {
      setSubmitting(false);
      // dispatch(setFormSubmitting(false));
    }
  };

  const initialValues = {
    dpu: 1,
    country: "",
    full_name: "",
    postal_code: "",
    line1: "",
    city: "",
    state: "",
    recaptcha: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={StripeSchema}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {({ values, isSubmitting, setFieldValue, setSubmitting, handleBlur }) => {
        const onBlur = (e) => {
          if (!values.recaptcha) {
            refRecaptcha.current.execute();
            setSubmitting(true);
          }
          handleBlur(e);
        };
        dispatch(setFormSubmitting(isSubmitting));

        return (
          <Form id="paymentForm">
            <div className="form-field">
              <label htmlFor="__card_element" className="form-label-2 sm mgt2">
                Cardholder’s name
              </label>

              <Field
                className="input-field input-styling w-input"
                name="full_name"
                placeholder="Full Name"
                disabled={isLoading}
                onBlur={onBlur}
              />
              <ErrorMessage name="full_name" />
            </div>

            <div className="form-filed">
              <label htmlFor="__card_holder" className="form-label-2 sm">
                Billing address
              </label>
              <Field
                className="input-field input-styling w-input"
                name="line1"
                placeholder="Billing Address"
                disabled={isLoading}
                onBlur={onBlur}
              />
              <ErrorMessage name="line1" />
            </div>
            <div className="field-col">
              <label htmlFor="__country" className="form-label-2 sm">
                Country
              </label>

              <div className="country_dropdown_container">
                <Field
                  className="input-field input-styling w-input"
                  id="__country"
                  as="select"
                  name="country"
                  placeholder="Country"
                  disabled={isLoading}
                  style={{
                    color: values.country === "" ? "#a3acb9" : "black",
                    background: `url(${icon})${
                      isLoading ? "#d0dae3" : "#fff"
                    } no-repeat right 1rem center`,
                  }}
                >
                  <option value="">Country</option>
                  {countires.map((place, i) => {
                    return (
                      <option key={i} value={place}>
                        {place}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage name="country" />
              </div>
            </div>

            <div className="three-form-field">
              <div className="form-filed">
                <label htmlFor="__city" className="form-label-2 sm">
                  City
                </label>
                <Field
                  className="input-field input-styling w-input"
                  name="city"
                  placeholder="City"
                  disabled={isLoading}
                  onBlur={onBlur}
                />
                <ErrorMessage name="city" />
              </div>
              <div className="form-filed">
                <label htmlFor="__state" className="form-label-2 sm">
                  State
                </label>
                <Field
                  className="input-field input-styling w-input"
                  name="state"
                  placeholder="State"
                  disabled={isLoading}
                  onBlur={onBlur}
                />
                <ErrorMessage name="state" />
              </div>
              <div className="form-filed">
                <label htmlFor="__zipcode" className="form-label-2 sm">
                  ZIP code
                </label>
                <Field
                  className="input-field input-styling w-input"
                  name="postal_code"
                  placeholder="Zip"
                  disabled={isLoading}
                  onBlur={onBlur}
                />
                <ErrorMessage name="postal_code" />
              </div>
            </div>
            <div className="form-field">
              <div className=" terms_link">
                <input type="checkbox" id="__agree" required />
                <span htmlFor="__agree">
                  I understand and agree with All Time Design's{" "}
                  <a
                    href="https://alltimedesign.com/terms"
                    target="_blank"
                    className="jsModalTrigger link-5 w--current"
                    rel="noreferrer"
                  >
                    <span className="purple-text">Terms of service</span>
                  </a>
                  .
                </span>
              </div>
            </div>
            <ReCAPTCHA
              ref={refRecaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                setFieldValue("recaptcha", value);
                setSubmitting(false);
              }}
              size="invisible"
            />
            {/* <Button
              disabled={disabled || isSubmitting || !uId || isLoading}
              type="submit"
            >
              Pay{allPlans?.currency}
              {(!promoData || !isPlanAvailable) &&
                activePlanType &&
                (
                  Number(activePlanType?.total_offer_price) *
                  Number(activePlan?.quantity)
                )?.toLocaleString()}
              {promoData && isPlanAvailable && totalAmount.toLocaleString()}
            </Button> */}
          </Form>
        );
      }}
    </Formik>
  );
}

export default PaymentForm;
